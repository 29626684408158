<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="用户组">
              <a-input v-model="queryParam.name" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons" >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => this.queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.name"
      :columns="columns"
      :data="loadData"
    >
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handlePermission(record)">权限</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="你确定要删除吗?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDelete(record)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>

    <role-form
      ref="createModal"
      :visible="roleFormVisible"
      :loading="confirmLoading"
      :model="formModel"
      @cancel="handleCancel('edit')"
      @ok="handleRoleSave"
    />
    <role-permission-form
      ref="permissionModal"
      :visible="permissionFormVisible"
      :loading="confirmLoading"
      :model="formModel"
      @cancel="handleCancel('permission')"
      @ok="handlePermissionSave"
    />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { deleteRole, getRoleList, saveRole, saveRolePermission } from '@/api/manage'
import RoleForm from './modules/RoleForm'
import RolePermissionForm from './modules/RolePermissionForm'

export default {
  name: 'UserList',
  components: {
    STable,
    RoleForm,
    RolePermissionForm
  },
  data () {
    return {
      roleFormVisible: false,
      permissionFormVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {},
      loadData: parameter => {
        return getRoleList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '用户组',
          dataIndex: 'name'
        },
        {
          title: '名称',
          dataIndex: 'label'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    handlePermission (record) {
      this.formModel = record
      this.permissionFormVisible = true
    },
    handleAdd () {
      this.$refs.createModal.form.resetFields()
      this.formModel = null
      this.roleFormVisible = true
    },
    handleEdit (record) {
      this.formModel = record
      this.roleFormVisible = true
    },
    handleRoleSave () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          saveRole(values).then(() => {
            this.roleFormVisible = false
            form.resetFields()
            this.$refs.table.refresh()
            this.$message.success('修改成功')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handlePermissionSave () {
      saveRolePermission({ id: this.$refs.permissionModal.model.id, permissions: this.$refs.permissionModal.targetKeys })
      .then(() => {
        this.permissionFormVisible = false
        this.$message.success('修改成功')
      })
    },
    handleDelete (record) {
      deleteRole(record).then(() => {
        this.$message.success('删除成功')
        this.$refs.table.refresh()
      })
    },
    handleCancel (type) {
      if (type === 'permission') {
        this.permissionFormVisible = false
      } else if (type === 'edit') {
        this.roleFormVisible = false
      }
    }
  }
}
</script>

<style scoped>

</style>
