<template>
  <a-modal
    :title="'修改' + (model ? model.label : '用户组') + '权限'"
    :width="640"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-transfer
        v-if="model"
        :data-source="permissions"
        :titles="['所有权限', '已选权限']"
        :target-keys="targetKeys"
        :render="item => item.label"
        @change="handleChange"
      />
    </a-spin>
  </a-modal>
</template>

<script>
import { getRolePermissionList } from '@/api/manage'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      permissions: [],
      targetKeys: []
    }
  },
  mounted () {
    this.permissions = []
    this.targetKeys = []
    if (this.model) {
      this.load()
    }
    this.$watch('model', () => this.load())
  },
  methods: {
    load () {
      getRolePermissionList({ id: this.model.id }).then(res => {
        const allPermissions = res.all
        allPermissions.forEach(p => {
          p.key = p.name
          p.title = p.label
        })
        const current = res.current.map(p => p.name)
        this.permissions = allPermissions
        this.targetKeys = current
      })
    },
    handleChange (nextTargetKeys) {
      this.targetKeys = nextTargetKeys
    }
  }
}
</script>
